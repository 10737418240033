export const MAIN_URL = 'https://staff.tng-typography.ru/api'
export const WS_MAIN_URL = 'wss://staff.tng-typography.ru/api/ws/notifications'

export const data = [
    {
        type: 'bar',
        table: 2,
        _id: Math.random().toString(36).substr(2, 9),
        utc_datetime: 'Fri May 22 2024 23:36:34 GMT+0300 (Москва, стандартное время)',
    },
    {
        type: 'hookah',
        table: 2,
        _id: Math.random().toString(36).substr(2, 9),
        utc_datetime: 'Fri May 23 2024 12:46:38 GMT+0300 (Москва, стандартное время)',
    },
    {
        type: 'hookah',
        table: 5,
        _id: Math.random().toString(36).substr(2, 9),
        utc_datetime: 'Fri May 23 2024 12:43:44 GMT+0300 (Москва, стандартное время)',
    },
    {
        type: 'bill',
        table: 6,
        _id: Math.random().toString(36).substr(2, 9),
        utc_datetime: 'Fri May 22 2024 21:59:14 GMT+0300 (Москва, стандартное время)',
    },
];