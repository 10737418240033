import './NotificationColumn.css';
import { AnimatePresence, motion } from "framer-motion";
import hat from '../../assets/images/hat.png';
import SwipeableCard from '../SwipeableCard/SwipeableCard';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { useEffect, useState } from 'react';
import mainApi from '../../assets/api/MainApi';
import useMainWebsocket from '../../assets/hooks/useMainWebSocket';
import DeleteConfirmPopup from '../DeleteConfirmPopup/DeleteConfirmPopup';

function NotificationColumn({ setOpen, setCurId, data, isPreloaderVisible, Submit, preloaderSubmit, isOpen, curId}) {


    function Cur(itemId) {
        setOpen(true)
        setCurId(itemId)
    }

    return (
        <>
            <div id='notification-column' className="notification-column">
                <div className={`notification-column__box`} >
                    {/* <img src={cosmo} alt='' className='notification-column__header__img'></img> */}
                    <div className='notification-column__header'>
                        <img src={hat} alt='' className='notification-column__header__img'></img>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                            <path d="M0 10H16" stroke="#DEB75D" stroke-width="2" />
                            <path d="M0 16H16" stroke="#DEB75D" stroke-width="2" />
                            <path d="M0 4H16" stroke="#DEB75D" stroke-width="2" />
                        </svg> */}
                        <div></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="22" viewBox="0 0 76 22" fill="none">
                            <path d="M14.4008 15.9039V5.45147L21.5288 12.8777V6.09623H23.0501V16.4923L15.9221 9.06646V15.9039H14.4008Z" fill="white" />
                            <path d="M34.0369 10.8037H38.094V11.1119C38.094 12.065 37.9813 13.1578 37.2063 14.2506C36.6848 14.9792 35.5724 16.0998 33.5017 16.0998C31.9237 16.0998 30.797 15.5115 29.9514 14.6709C28.8669 13.5922 28.4585 12.3172 28.4585 11.0136C28.4585 9.71083 28.8953 8.40763 29.8672 7.39903C31.0364 6.18007 32.4872 5.90003 33.586 5.90003C35.0651 5.90003 36.0512 6.46056 36.5864 6.83851C37.0232 7.14677 37.3752 7.48327 37.6714 7.90356L36.6006 8.91215C36.2907 8.46408 35.3751 7.30069 33.586 7.30069C31.5011 7.30069 30.0361 8.88437 30.0361 11.0136C30.0361 13.1856 31.6279 14.6987 33.5438 14.6987C35.1072 14.6987 36.2485 13.6341 36.488 12.2048H34.0369V10.8037Z" fill="white" />
                            <path d="M8.50833 7.64037V6.23927H0V7.64037H3.49352V16.0999H8.50833V14.6988H5.01481V7.64037H8.50833Z" fill="white" />
                            <path d="M45.7702 22H44.6923V0H45.7702V22Z" fill="white" />
                            <path d="M53.5612 6.58063V8.87655H52.705V6.58063H52.3685V5.85472H52.705V4.82186H53.5612V5.85472H53.8977V6.58063H53.5612Z" fill="white" />
                            <path d="M55.2882 7.36221C55.2882 7.8438 55.639 8.22042 56.1298 8.22042C56.6211 8.22042 56.9718 7.8438 56.9718 7.36221C56.9718 6.88062 56.6211 6.51061 56.1298 6.51061C55.639 6.51061 55.2882 6.88062 55.2882 7.36221ZM56.888 5.85482H57.7438V8.87665H56.888V8.48591C56.579 8.92516 56.1719 8.97412 55.9542 8.97412C55.0213 8.97412 54.4037 8.24821 54.4037 7.36221C54.4037 6.46916 55.0147 5.75736 55.9125 5.75736C56.144 5.75736 56.5998 5.79925 56.888 6.24556V5.85482Z" fill="white" />
                            <path d="M58.5045 5.85472H59.3607V6.24546C59.5221 6.0223 59.8099 5.75725 60.3007 5.75725C60.5676 5.75725 60.9605 5.83399 61.1986 6.12682C61.4096 6.38526 61.4376 6.68515 61.4376 6.97842V8.87655H60.5818V7.22936C60.5818 7.06222 60.5743 6.79672 60.42 6.6362C60.287 6.49683 60.1043 6.48272 60.02 6.48272C59.7957 6.48272 59.6201 6.55946 59.4942 6.74777C59.3678 6.94358 59.3607 7.16012 59.3607 7.33432V8.87655H58.5045V5.85472Z" fill="white" />
                            <path d="M62.9132 7.36221C62.9132 7.8438 63.2639 8.22042 63.7548 8.22042C64.246 8.22042 64.5968 7.8438 64.5968 7.36221C64.5968 6.88062 64.246 6.51061 63.7548 6.51061C63.2639 6.51061 62.9132 6.88062 62.9132 7.36221ZM64.4983 5.85482H65.3541V8.76508C65.3541 9.1417 65.3616 9.56728 65.0109 9.97213C64.7094 10.3139 64.2602 10.5027 63.741 10.5027C62.8848 10.5027 62.2113 10.0211 62.1058 9.23255H63.032C63.0954 9.57434 63.369 9.77676 63.7477 9.77676C64.4846 9.77676 64.4983 9.09981 64.4983 8.92517V8.4718C64.2039 8.91811 63.7902 8.97412 63.5654 8.97412C62.6396 8.97412 62.0286 8.24821 62.0286 7.36221C62.0286 6.51061 62.597 5.75736 63.5446 5.75736C64.113 5.75736 64.3791 6.06431 64.4983 6.24556V5.85482Z" fill="white" />
                            <path d="M66.9756 8.87631H66.1194V5.85447H66.9756V8.87631ZM66.5472 4.06836C66.8284 4.06836 67.0594 4.29857 67.0594 4.57774C67.0594 4.8569 66.8284 5.08755 66.5472 5.08755C66.2666 5.08755 66.0351 4.8569 66.0351 4.57774C66.0351 4.29857 66.2666 4.06836 66.5472 4.06836Z" fill="white" />
                            <path d="M69.9029 6.96445C69.8333 6.55299 69.4963 6.35718 69.1802 6.35718C68.8649 6.35718 68.5346 6.5596 68.465 6.96445H69.9029ZM70.689 8.03215C70.5977 8.2553 70.2536 8.97416 69.1944 8.97416C68.7031 8.97416 68.3315 8.8348 68.0295 8.54858C67.693 8.23458 67.5525 7.84384 67.5525 7.36931C67.5525 6.76909 67.7981 6.39908 68.0158 6.18298C68.3736 5.83414 68.7944 5.7574 69.1598 5.7574C69.7765 5.7574 70.1348 6.00128 70.345 6.25266C70.6678 6.63634 70.7103 7.11087 70.7103 7.43899V7.50867H68.437C68.437 7.68992 68.4863 7.88573 68.5838 8.01804C68.6751 8.14373 68.8649 8.30426 69.1944 8.30426C69.5171 8.30426 69.7628 8.15078 69.9029 7.90646L70.689 8.03215Z" fill="white" />
                            <path d="M71.3151 5.85472H72.1713V6.30852C72.3047 6.05009 72.487 5.9244 72.5504 5.88956C72.6834 5.80621 72.8519 5.75725 73.0554 5.75725C73.2026 5.75725 73.3219 5.79209 73.4203 5.82693L73.3077 6.62252C73.196 6.54579 73.0834 6.51051 72.9078 6.51051C72.7468 6.51051 72.5078 6.54579 72.3393 6.75527C72.1784 6.95725 72.1713 7.21569 72.1713 7.42517V8.87655H71.3151V5.85472Z" fill="white" />
                            <path d="M75.4249 6.57354C75.2214 6.38522 75.0174 6.37111 74.9265 6.37111C74.7235 6.37111 74.5971 6.46902 74.5971 6.61543C74.5971 6.69217 74.6321 6.8108 74.8702 6.88754L75.0742 6.9506C75.3123 7.02734 75.6706 7.14597 75.8461 7.38324C75.9366 7.50893 76 7.69019 76 7.88555C76 8.15766 75.9087 8.42977 75.663 8.65336C75.4174 8.87651 75.1234 8.97442 74.7514 8.97442C74.1196 8.97442 73.7617 8.67409 73.5724 8.47166L74.0211 7.95524C74.1896 8.1506 74.4423 8.30452 74.6946 8.30452C74.9336 8.30452 75.1159 8.18544 75.1159 7.97596C75.1159 7.78765 74.9616 7.71091 74.8498 7.66946L74.653 7.59934C74.4352 7.5226 74.183 7.42514 74.0007 7.23682C73.8602 7.08997 73.7688 6.90165 73.7688 6.65733C73.7688 6.36406 73.9094 6.12018 74.0845 5.97332C74.3235 5.785 74.6321 5.75722 74.8778 5.75722C75.1021 5.75722 75.4599 5.785 75.8461 6.07784L75.4249 6.57354Z" fill="white" />
                            <path d="M53.5611 15.8589H52.7049V10.632H53.5611V15.8589Z" fill="white" />
                            <path d="M56.7507 14.3516C56.7507 13.877 56.3862 13.4929 55.8949 13.4929C55.4037 13.4929 55.0392 13.877 55.0392 14.3516C55.0392 14.812 55.3966 15.2027 55.8949 15.2027C56.3929 15.2027 56.7507 14.812 56.7507 14.3516ZM57.1227 13.1653C57.4242 13.4374 57.6353 13.8559 57.6353 14.3516C57.6353 14.8398 57.4242 15.2587 57.1227 15.5309C56.8633 15.7681 56.47 15.9564 55.8949 15.9564C55.3194 15.9564 54.9266 15.7681 54.6672 15.5309C54.3657 15.2587 54.1546 14.8398 54.1546 14.3516C54.1546 13.8559 54.3657 13.4374 54.6672 13.1653C54.9266 12.928 55.3194 12.7397 55.8949 12.7397C56.47 12.7397 56.8633 12.928 57.1227 13.1653Z" fill="white" />
                            <path d="M59.0741 12.837V14.5468C59.0741 14.7073 59.0879 14.9239 59.2564 15.0773C59.3614 15.1678 59.5299 15.2308 59.6913 15.2308C59.8527 15.2308 60.0212 15.1678 60.1263 15.0773C60.2948 14.9239 60.3085 14.7073 60.3085 14.5468V12.837H61.1643V14.6862C61.1643 14.9583 61.1297 15.3217 60.7932 15.6145C60.5121 15.8588 60.0629 15.9567 59.6913 15.9567C59.3193 15.9567 58.8702 15.8588 58.5895 15.6145C58.2534 15.3217 58.2179 14.9583 58.2179 14.6862V12.837H59.0741Z" fill="white" />
                            <path d="M61.9169 12.837H62.7731V13.2277C62.9344 13.0046 63.2222 12.7395 63.7131 12.7395C63.98 12.7395 64.3728 12.8163 64.6109 13.1091C64.822 13.3675 64.8499 13.6674 64.8499 13.9607V15.8588H63.9942V14.2116C63.9942 14.0445 63.9866 13.779 63.8323 13.6185C63.6993 13.4791 63.5166 13.465 63.4324 13.465C63.208 13.465 63.0324 13.5417 62.9065 13.73C62.7801 13.9259 62.7731 14.1424 62.7731 14.3166V15.8588H61.9169V12.837Z" fill="white" />
                            <path d="M66.3254 14.3445C66.3254 14.8261 66.6762 15.2027 67.167 15.2027C67.6583 15.2027 68.009 14.8261 68.009 14.3445C68.009 13.8629 67.6583 13.4929 67.167 13.4929C66.6762 13.4929 66.3254 13.8629 66.3254 14.3445ZM67.9106 12.8371H68.7663V15.7469C68.7663 16.124 68.7739 16.5496 68.4232 16.9544C68.1216 17.2962 67.6725 17.4849 67.1533 17.4849C66.2971 17.4849 65.6236 17.0034 65.518 16.2148H66.4443C66.5077 16.5566 66.7813 16.759 67.1599 16.759C67.8968 16.759 67.9106 16.0821 67.9106 15.9074V15.4541C67.6162 15.9004 67.2025 15.9564 66.9777 15.9564C66.0519 15.9564 65.4413 15.2305 65.4413 14.3445C65.4413 13.4929 66.0098 12.7396 66.9568 12.7396C67.5253 12.7396 67.7913 13.0466 67.9106 13.2278V12.8371Z" fill="white" />
                            <path d="M71.715 13.9467C71.6454 13.5353 71.3084 13.3395 70.9922 13.3395C70.677 13.3395 70.3467 13.5419 70.277 13.9467H71.715ZM72.5011 15.0144C72.4098 15.2376 72.0657 15.9564 71.0064 15.9564C70.5152 15.9564 70.1436 15.8171 69.8416 15.5309C69.5051 15.2168 69.3645 14.8261 69.3645 14.3516C69.3645 13.7514 69.6102 13.3813 69.8279 13.1653C70.1857 12.8164 70.6065 12.7397 70.9718 12.7397C71.5886 12.7397 71.9469 12.9836 72.1571 13.2349C72.4798 13.6186 72.5224 14.0931 72.5224 14.4213V14.4909H70.2491C70.2491 14.6722 70.2983 14.868 70.3959 15.0003C70.4872 15.126 70.677 15.2865 71.0064 15.2865C71.3292 15.2865 71.5749 15.1331 71.715 14.8887L72.5011 15.0144Z" fill="white" />
                        </svg>
                    </div>
                    
                    {data?.length > 0 ?
                        <>
                            <AnimatePresence >
                                {
                                    isPreloaderVisible ?
                                        <MiniPreloader />
                                        :
                                        data?.map((item, i) => (
                                            <motion.div
                                                layout
                                                initial={{ y: 300 }}
                                                animate={{ y: 0 }}
                                                key={item.id}
                                                exit={{ x: 500 }}
                                                transition={{ duration: 0.1 }}
                                                className={`notification-column__swipeable-card-content`}
                                            >
                                                <SwipeableCard
                                                    item={item}
                                                    Cur={Cur}
                                                />
                                            </motion.div>
                                        ))
                                }
                            </AnimatePresence>
                        </>
                        :
                        <p className='notification-column_empty'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="90" viewBox="0 0 43 90" fill="none">
                                <path d="M19.3076 2.41134C20.1479 1.19622 21.8524 1.19622 22.6927 2.41134C26.4668 7.86889 28.5003 14.4225 28.5003 21.1504L28.5003 40.0002C28.5003 41.4333 27.3938 42.5008 26.1431 42.5008L15.8572 42.5008C14.6065 42.5008 13.5 41.4333 13.5 40.0002L13.5 21.1504C13.5 14.4225 15.5336 7.86889 19.3076 2.41134Z" stroke="#777786" strokeWidth="3" />
                                <path d="M12.6667 22L4.17514 26.3534C2.83984 27.038 2 28.4123 2 29.9129L2 42.0001" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M30.0004 22.1206L38.8544 26.4588C40.2253 27.1306 41.0944 28.5241 41.0944 30.0508L41.0944 42.0008" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M2 36.668L12.0002 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M41.0944 36.668L31.0942 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M36.7165 1.73872C36.8268 1.49464 37.1734 1.49464 37.2836 1.73872L38.6457 4.75445C38.6768 4.82347 38.7321 4.87875 38.8012 4.90993L41.8169 6.27197C42.061 6.3822 42.061 6.72881 41.8169 6.83905L38.8012 8.20109C38.7321 8.23226 38.6768 8.28755 38.6457 8.35657L37.2836 11.3723C37.1734 11.6164 36.8268 11.6164 36.7165 11.3723L35.3545 8.35657C35.3233 8.28755 35.2681 8.23226 35.199 8.20109L32.1833 6.83905C31.9392 6.72881 31.9392 6.3822 32.1833 6.27197L35.199 4.90993C35.2681 4.87875 35.3233 4.82347 35.3545 4.75445L36.7165 1.73872Z" fill="#FFB31F" />
                                <path d="M22.0011 56.001L22.0011 88.0016" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M10.0012 56.001L10.0012 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M34.001 56.001L34.001 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                            Активных заказов нет
                        </p>
                    }
                </div>
            </div>
        </>
    );
}

export default NotificationColumn;